*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html,body{
	height:100vh;
	overflow:hidden;
	margin:0px;
	padding:0px;
}
@import 'common.scss';

