/* LandingPage.scss */
@import '../../assets/styles/variable.scss';



.landing-page { 
  @include flexBox(flex, column, flex-start, center);
  background-color: $black;
  max-width: 100%;
  margin: 0px auto;
  height: 100vh;
  position: relative; 
  padding-bottom: 20px;
  .welcome-wrapper{
    position: relative;
    height: 50vh;
    background-image: url('../../assets/images/bg/age-gate-bg.gif');
    background-size: cover;
    background-position: center;
    width: 100%;
    background-repeat: no-repeat;
    @include flexBox(flex, column, flex-end, center);
    padding-bottom:50px;
    @include until(320px){
      height: 40vh;
    }
    @include until(375px){
      height: 45vh;
    }
  
    h1 {
      font-family: 'cera_compact_cyblack';
      font-size: 30px;
      font-weight: 900;      
      color: $white-color;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      text-align: center;   
      letter-spacing: 5.76px;
      margin: 0 auto 20px;
      max-width:300px;
      @include until(375px){
        font-size: 25px;
        width:250px;
      }
      span{
        font-size: 26px;
        letter-spacing: 4.16px;
        display: block;
      }
    }
    .subtext{
      color:$white-color;
      font-size: 10px;
      font-family: 'cera_compact_cyregular';
      
    }
    .txt-logo-wrapper{
      position: absolute;
      bottom: 0;
    }
  }
  

  form { 
    padding: 25px 35px;
    width:100%;
    @include until(375px){
      padding:20px;
    }
    .form > div {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    
    label {
      font-size: 10px;
      color:$white-color;
      font-family: 'agrandirregular';
    }
    .select-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      
      label {
        margin-right: 10px;
      }
    
      .select-container > * {
        margin-right: 10px;
      }
    }
    .selectField,.selectFieldCountry{
      width:30%;
      .custom-select__control {
        background-color: transparent;
        border:none;
        border-bottom: 1px solid $white-color;
        border-radius: 0px;
        font-size: 25px;   
        box-shadow: unset;
        font-family: 'tungstenbook';  
        @include until(375px){
          font-size: 22px;
        }   
       .custom-select__value-container{
          padding-left: 0px; 
          .custom-select__single-value{
            color:$white-color!important;
          }  
          max-height: 150px;
          overflow-y: auto;
          .custom-select__input{
            color:$white-color!important;
          }       
        }
        .custom-select__indicators{
          .custom-select__indicator-separator{
            display: none;
          }
        }
      }
      .custom-select__menu{
        background-color: $black;
        color: $white-color;
        font-family: 'tungstenbook';
        margin: 0;       
        .custom-select__option{
          font-size: 22px;
          &.custom-select__option--is-selected,
          &:active{
            background-color: #c99b56!important;
          }
          &.custom-select__option--is-focused{
            background-color: transparent;
          }
        }
        .custom-select__menu-list{
          max-height:190px;
        }
      }
      
    }
    .selectFieldCountry{
      width:100%;
    }
    // .consentcheckField{
    //   padding:20px 0px;
    //   label{
    //     font-size: 9px;
    //     position: relative;
    //     display: block;
    //     padding-left:30px;
    //     text-transform: uppercase;
    //     input{
    //       position: absolute;
    //       left:0px;
    //       height: 20px;
    //       width: 20px;
    //       top:-5px;
    //     }
    //   }
    // }

    button[type='submit'] {
      background-color: transparent;
      color: #fff;
      border: none;
      border-radius: 0px;
      padding: 0px;
      font-family: 'tungstenbook';
      font-size: 25px;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
      margin:20px auto 0px;
      border-bottom:1px solid $white-color;
      text-transform: uppercase;
      width: auto;
      display: block;
      @include until(375px){
        margin: 10px auto 0px;
      }

      &:hover {
        background-color: #0062cc;
      }
    }
  }
  p{
    &.tandcTxt{
      padding: 0px 0px 10px;
      color: $white-color;
      text-align: center;
      font-size: 8px;    
      font-weight: 300;    
      font-family: 'cera_compact_cyregular';
      a{
        color: $white-color!important;
      }
    }
    &.glentxt{
      padding: 0px 20px 10px;
      color: $white-color;
      text-align: center;
      font-size: 8px;    
      font-weight: 300;      
      font-family: 'cera_compact_cyregular';
    }
  }
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;

  .loading-message {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: $blue-shade;
  }

  .loader {
    border: 0.5rem solid $gray-txt-2;
    border-top: 0.5rem solid $blue-shade;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}