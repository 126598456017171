
@import 'fonts';
@import 'mixins';

//colors variables 
$primary-color: #6A6DB5;
$secondary-color:#305FD8;
$white-color: #fff;
$black:#000;
$dark-gray:#282828;
$light-gray: #565656;
$gray-txt: #414141;
$border-radius:20px;

$blue-shade: #007bff;
$gray-txt-2: #6c757d;
$white-shade: #f8f9fa;


//breakpoints 
$extrasmall:375px;
$mediumasmall:414px;
$xs : 540px;
$sm : 767px;
$md : 992px
