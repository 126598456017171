@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');

@mixin regular(){
    font-family: "Open Sans", sans-serif;
    font-weight: 400;   
}
@mixin medium(){
    font-family: "Open Sans", sans-serif;
    font-weight: 500;   
}
@mixin semiBold(){
    font-family: "Open Sans", sans-serif;
    font-weight: 600;   
}
@mixin bold(){
    font-family: "Open Sans", sans-serif;
    font-weight: 700;   
}
@font-face {
    font-family: 'tungstenbook';
    src: url('../fonts/tungsten-book-webfont.woff2') format('woff2'),
         url('../fonts/tungsten-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'cera_compact_cyregular';
    src: url('../fonts/ceracompactcy-regular-webfont.woff2') format('woff2'),
         url('../fonts/ceracompactcy-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'cera_compact_cylight';
    src: url('../fonts/ceracompactcy-light-webfont.woff2') format('woff2'),
         url('../fonts/ceracompactcy-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'cera_compact_cyblack';
    src: url('../fonts/ceracompactcy-black-webfont.woff2') format('woff2'),
         url('../fonts/ceracompactcy-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'agrandirregular';
    src: url('../fonts/agrandir-regular-webfont.woff2') format('woff2'),
         url('../fonts/agrandir-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}