@import '../../assets/styles/variable.scss';


.header{
  position: fixed;
  width: 100%;
  background-color: $black;
  padding: 10px 15px;
  top: 0px;
  z-index: 1;
}
.header-wrapper {
  @include flexBox(flex, row, center, center);
  .headerIcon{
    font-size:24px;
    color:$primary-color;
  }
  .headerPageProfile {    
    @include flexBox(flex, row, space-between, center);
    .profile-img {
      border-radius: 50%;     
      height: 52px;
      width: 52px;
    }
    .title {
      padding-left: 10px;
      .pageName {
        color: $white-color;
        @include bold;
        font-size: 15px;
      }
      .chatCategory {
        color: $gray-txt;
        font-size: 12px;
       @include semiBold;
      }
    }
  }
}
