@import '../../assets/styles/variable.scss';

.chatLoginScreen{
    background-color: $white-color;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding:26px 22px 90px;
    position: absolute;
    width: 100%;
    bottom:0px;
    height: 350px;
    @include until(375px){
        padding: 26px 16px 70px;
        height:295px;
    }    
    h2{
        @include bold;
        font-size:22px;
        text-align: center;
        span{
            text-decoration: underline;
        }
    }
    .primaryBtn,.secondaryBtn{
        border-radius: 11px;
        background-color: $black;
        width:100%;
        color:$white-color;
        margin-top:20px;
        @include bold;
        font-size:18px;
        padding:12px;
        border:none;
        @include until(320px){
           font-size:16px; 
           margin-top:20px;
        }
    }
    .secondaryBtn{
        background-color: #AAA;
        margin-top:18px;
    }
    .textcondition{
        @include regular;
        font-size:14px;
        color: #747272;
        padding:15px 20px 0px;
        text-align: center;
        @include until(320px){
            font-size:12px;
            padding: 15px 20px 0px;
        }
        a{
            color:$black;
            @include bold;
            text-decoration: none;
        }
    }
}