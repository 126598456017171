//Mixins for responsiveness

@mixin from($device) { 
    @media screen and (min-width: $device) { 
        @content; 
    } 
} 

@mixin until($device) { 
    @media screen and (max-width: $device) { 
        @content; 
    } 
}


//flexbox mixin 
@mixin flexBox($display, $directions, $justCont, $itemsAlign){
    display: $display;
    flex-direction:$directions;
    justify-content: $justCont;
    align-items: $itemsAlign;

}