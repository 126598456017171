
@import '../../assets/styles/variable.scss';

.notsupportScreen{
    background-color: #000;
    height: 100vh;
    position: relative;
    .container{
        max-width: 600px;
        margin: 0 auto;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img{
            margin-bottom: 20px;
        }
        .content{
           position: relative; 
          
           p{
            color:#fff;
            font-size: 22px;
            text-align: center;
            max-width: 500px;
            margin-bottom:20px;
            @include medium;
            &:first-child{
                font-style: italic;
            }
            a{
                color:$white-color;
            }
            &.tandcTxt{
                font-size:10px;
                padding-top:20vh;
                @include from(800px){
                    padding-top: 7vh;
                }
            }
            &.glentxt{
                font-size: 10px;
            }
           }
        }
    }
    
}