@import "../../assets/styles/variable.scss";

.footer {
  @include flexBox(flex, row, space-between, center);
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 15px 20px 20px 20px;
  background-color: $black;
  .footicon {
    color: $primary-color;
    font-size: 24px;
  }
  .msgInputField {
    position: relative;
    width: 100%;
    input {
      border-radius: $border-radius;
      border: none;
      background-color: $dark-gray;
      color: $white-color;
      height: 35px;
      padding: 10px 30px 10px 10px;
      width: 100%;
      @include regular;
      &:focus {
        border: none;
        outline: none;
        box-shadow: unset;
      }
    }
    button {
      border: 0;
    }
    .foot-input-icon {
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }  
  .error {
    position: absolute;
    bottom: 3px;
    left: 28px;
    font-family: "Open Sans", sans-serif;
    color: red;
    font-size: 13px;
  }
}
