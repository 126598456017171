@import '../../assets/styles/variable.scss';


.ageInvalidScreen {
    background-color: #000;
    height: 100vh;
    text-align: center;   
    @include flexBox(flex, column, space-between, center);   
    padding:50px 25px;
    @include until(375px){
        padding:30px 18px 60px;
    }
    h1{
        color:$white-color;
    }
    .topSection{
        padding-top:72px;
        @include flexBox(flex, column, center, center);
        @include until(375px){
            padding-top:30px;
        }
        .logoImageWrapper{            
            img{
                max-width: 100%;              
                height: 91px;
            }
        }
        h1{
            font-family: 'cera_compact_cyblack';
            font-size: 36px;
            font-weight: 900;      
            color: $white-color;            
            text-align: center;
            letter-spacing: 5.76px;
            @include until(375px){
                font-size: 30px;
            }
        }
    }
    .invalidmsg{        
        h2{
            color:$white-color;
            font-family: 'cera_compact_cyblack';
            font-size: 22px;
            text-transform:uppercase;
            padding-bottom: 20px;
        }
        p{
            text-transform:uppercase;
            color:$white-color;
            font-family: 'cera_compact_cyblack';
        }
        button{
            background-color: transparent;
            color: $white-color;
            border: none;
            border-radius: 0px;
            padding: 0px;
            font-family: 'tungstenbook';
            font-size: 25px;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out;
            margin:35px auto 0px;
            border-bottom:1px solid $white-color;
            text-transform: uppercase;
            width: auto;
            display: block;
            @include until(375px){
                margin-top:20px
            }
        }
    }
    .termstxt{
        p{
            color:$white-color;
            padding-top:10px;
            font-weight: 300;    
            font-family: 'cera_compact_cylight';  
            font-size:8px;
            &:last-child{
                font-size: 8px;
            }
            a{
                color:$white-color;
            }
        }
    }
}