@import '../../assets/styles/variable.scss';

.startChatScreen{
    background-color: $black;
    height: 100vh;
    position: relative;
    overflow:hidden;
    .topSection{
        padding-top:45px;
        @include until(375px){
            padding-top:30px;
        }
        @include flexBox(flex, column, center, center);
        .logoImageWrapper{            
            img{
                max-width: 100%;              
                height: 91px;
                @include until(375px){
                    height: 75px;
                }
            }
        }
        h1{
            font-family: 'cera_compact_cyblack';
            font-size: 36px;
            font-weight: 900;      
            color: $white-color;            
            text-align: center;
            letter-spacing: 5.76px;
            max-width:min-content;
            @include until(375px){
                font-size: 30px;
                letter-spacing: 4.76px;
                max-width: min-content;
            }
        }
    }
    .stratchatMain{
        background-color: $white-color;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding:26px 26px 90px;
        position: absolute;
        width: 100%;
        bottom:0px;
        @include until(375px){
            padding: 26px 20px 90px;
        }
        .logowrap{
            text-align: center;
            margin-bottom: 10px;
            @include until(320px){
                margin-bottom: 10px;
            }
            img{
                height:60px;
               
            }
        }
        h2{
            @include bold;
            font-size:22px;
            text-align: center;
            @include until(360px){
                font-size:19px;
            }
            span{
                text-decoration: underline;
            }
        }
        .welcometxt{
            @include regular;
            font-size:17px;
            text-align: center;
            padding-top:10px;
            @include until(375px){
                font-size:14px
            }
        }
        .primaryBtn{
            border-radius: 11px;
            background-color: $black;
            width:100%;
            color:$white-color;
            margin-top:15px;
            @include bold;
            font-size:18px;
            padding:12px;
           border:none;
           @include until(320px){
                font-size:16px;
            }   
        }
        .poweredby {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            padding-top: 40px;
            p {
                font-family: "Open Sans", sans-serif;
                font-weight: 500;
                font-size: 16px;
            }
            svg {
                font-size: 24px;
            }
        }
        .footer{
            background-color: transparent;
            padding:0px;
            position: relative;
            flex-direction: column;
            p{
                &.tandcTxt{
                  padding: 20px 0px;
                  color: $black;
                  text-align: center;
                  font-size: 10px;    
                  font-weight: 300;    
                  font-family: 'cera_compact_cyregular';
                  a{
                    color: $black;
                  }
                }
                &.glentxt{
                 
                  color: $black;
                  text-align: center;
                  font-size:8px;    
                  font-weight: 300;      
                  font-family: 'cera_compact_cyregular';
                  padding-top:15px;
                }
              }
        }
    }
    &.start-chat-open{
        .stratchatMain{
            background-color: #cfcfcf;
            height: 460px;
            @include until(375px){
                padding-bottom: 90px;
                height: 400px;
            } 
            .logowrap{
                margin-bottom: 45px;
                @include until(320px){
                    margin-bottom: 20px;
                }
               
            }
            h2, p, .action-wrap, .poweredby {
                display: none;
            }
        }
        
    }
}