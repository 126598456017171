@import "../../assets/styles/variable.scss";

body,
* {
  margin: 0px;
  padding: 0px;
}
.guestuserwrapper {
  background-color: $black;
  max-width: 100%;
  margin: 0px auto;
  height: 100vh;
  position: relative;
}

.chat-view-wrapper {
  padding: 70px 40px 15px 50px;
  overflow-y: auto;
  height: 91vh;
}
.chat-container {
  @include flexBox(flex, column, flex-end, flex-start);
  .chatMsg {
    border-radius: 20px;
    @include regular;
    font-size: 16px;
    color: $white-color;
    padding: 10px;
    margin: 5px 0px;
    max-width: 85%;
    &.sender {
      align-self: flex-end;
      &.previousMsg {
        background-color: #6a6db5;
        position: relative;
        .msgTick {
          position: absolute;
          right: -29px;
          bottom: 0px;
          color: #305fd8;
          font-size: 20px;
        }
      }
      &.recentMsg {
        background-color: $primary-color;
      }
    }
    &.reciver {
      align-self: flex-start;
      background-color: $dark-gray;
      position: relative;
      &.recomendation img {
        max-width: 100%;
        border-radius: 15px;
        height: auto;
      }
      .userProf {
        color: $black;
        border: 1px solid $gray-txt-2;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: -40px;
        bottom: 0px;
        @include semiBold;
        font-size: 18px;
        img {
          width: 29px;
          height: 29px;
        }
      }
    }
    &.question {
      .queTxt {
        padding: 0px 15px;
        font-size: 14px;
        @include semiBold;
      }
      .ansOptions {
        margin-top: 10px;
        border-radius: 10px;
        padding: 10px;
        background-color: $light-gray;
        text-align: center;
        cursor: pointer;
        font-size: 13px;
        @include semiBold;
        text-transform: uppercase;
      }
    }
  }
}

.ansWrapper {
  margin-top: 20px;
}

.final {
  padding: 10px 0px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
