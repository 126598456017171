@import "../../assets/styles/variable.scss";

.loader {
  align-items: center;
  display: flex;
  justify-content: center;
}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: grey;
  display: inline-block;
  height: 8px;
  margin: 0px 3px;
  width: 8px;
  border-radius: 50%;
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot {
  0% {
    background-color: grey;
    transform: scale(1);
  }
  50% {
    background-color: $primary-color;
    transform: scale(1.3);
  }
  100% {
    background-color: grey;
    transform: scale(1);
  }
}
