@import '../../assets/styles/variable.scss';

.PageProfile {
    @include flexBox(flex,column,center, center);
    text-align: center;
    margin-bottom:15px;
    padding-top:65px;
    .profile-img {
      border-radius: 50%;      
      height: 85px;
      width: 85px;
    }
    .title {
      padding-left: 10px;
      .pageName {
        color: $white-color;
        @include bold();
        font-size: 22px;
        padding-top: 10px;
        
      }
      .people-count {
        color: $white-color;
        font-size: 14px;
        @include semiBold();
        padding-top: 5px;        
      }
      .chatCategory {
        color: $gray-txt;
        font-size: 14px;
        @include semiBold();
        padding-top: 5px;
       
      }
      .profileBtn {
        text-transform: uppercase;
        background-color:$dark-gray;
        border-radius: $border-radius;
        color: $white-color;
        font-size: 14px;
        @include regular;
        padding: 8px 12px;
        text-decoration: none;
        margin-top: 15px;
        display: inline-block;
        
      }
    }
  }